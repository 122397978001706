import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';

const DatePickerComponent = ({ value, onChange }) => {
  // Generate next 7 days using Moment.js
  const generateNext7Days = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const date = moment().add(i, 'days'); // Add `i` days to today
      const formattedDate = date.format('YYYY-MM-DD'); // Value
      const label = `${date.format('dddd')} ${date.format('DD/MM/YY')}`; // Label
      days.push({ label, value: formattedDate });
    }
    return days;
  };

  const options = generateNext7Days();

  return (
    <Dropdown
      value={value}
      options={options}
      onChange={(e) => onChange(e.value)}
      placeholder="Select a day"
      optionLabel="label" // Use label as display value
      optionValue="value" // Use value as the internal value
      style={{ width: '100%', textAlign:'center', alignItems:'center', alignContent:'center' }}
    />
  );
};

export default DatePickerComponent;