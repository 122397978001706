import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../context';
import CustomerService from '../../service/CustomerService';
import CateringBasicInfoSkeleton from '../Catering/CateringBasicInfoSkeleton';
import CustomerFormDialog from '../Catering/CustomerForm';
import CustomerBasicInfo from './CustomerBasicInfo';
import CustomerConfiguration from './CustomerConfiguration';
import CustomerUsers from './CustomerUsers';
import { adminRole, cateringAdmin, cateringOperator, customerAdmin, customerOperator } from '../../constants/auth';
import CustomersLocations from '../Catering/CustomerLocations';
import ExternalAuthConfiguration from './ExternalAuthConfiguration';
import QRCode from 'react-qr-code';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
const CustomerOverview = (props) => {
    const { history } = props
    const authContext = useContext(AuthContext);

    let customerId = 0
    if (history?.location?.state?.id) {
        customerId = history.location.state.id
    } else if (authContext.customerId) {
        customerId = authContext.customerId
    }

    const [updateCustomerDialog, setUpdateCustomerDialog] = useState(false);
    const [customerData, setCustomerData] = useState(null)
    const [deleteCustomerDialog, setDeleteCustomerDialog] = useState(false);
    const [displayQR, setDisplayQR] = useState(false);
    const [customerPublicLink, setCustomerPublicLink] = useState();

    const customerService = new CustomerService();
    const { t } = useTranslation();
    const toast = useRef(null);
    const historyRouter = useHistory();
    const downloadContentRef = useRef();

    useEffect(() => {
        getCustomerData()
    }, [])
    const getCustomerData = () => {
        customerService.get(customerId).then((data) => {
            setCustomerData(data)
        })
    }
    const hideDialog = () => {
        setUpdateCustomerDialog(false);
    }
    const updateCustomer = (data) => {
        if (data) {
            let submitRequest = { ...data }
            customerService.update(customerId, submitRequest).then((value) => {
                setUpdateCustomerDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('customerUpdated'), life: 3000 })
                getCustomerData()
            })
        }
    }
    const hideDeleteCustomerDialog = () => {
        setDeleteCustomerDialog(false);
    }
    async function deleteSelectedCustomer() {
        customerService.delete(customerId).then(data => {
            setDeleteCustomerDialog(false);
            historyRouter.goBack()
        })
    }
    const generatePublicQR = () => {
        customerService.getCustomerPublicMenuLink(customerId, authContext?.cateringId).then(data => {
            setDisplayQR(true)
            setCustomerPublicLink(data)
        })
    }
    const downloadAsFile = () => {
        const element = downloadContentRef.current;
        const content = element.innerHTML; // Get the HTML content

        const blob = new Blob([content], { type: "text/html" });
        const url = URL.createObjectURL(blob);

        // Create a temporary link to trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = "content.html"; // Filename for the download
        a.click();

        // Revoke the URL to free up resources
        URL.revokeObjectURL(url);
    };
    const downloadAsPDF = async () => {
        const element = downloadContentRef.current;

        // Use html2canvas to take a snapshot of the element
        const canvas = await html2canvas(element);
        const imgData = canvas.toDataURL("image/png");

        // Create a PDF using jsPDF
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 10, 10, 190, 0); // Adjust dimensions
        pdf.save("content.pdf");
    };
    const leftInfoToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("edit")} icon="pi pi-pencil" className="p-button-success mr-2" onClick={() => setUpdateCustomerDialog(true)} />
                </div>
            </React.Fragment>
        )
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div >
                    <Button label={t("delete")} icon="pi pi-trash" className="p-button-danger mr-2" onClick={() => setDeleteCustomerDialog(true)} />
                </div>
            </React.Fragment>
        )
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div >
                    <Button label={t("showPublicMenuQR")} icon="pi pi-qrcode" className=" mr-2" onClick={() => generatePublicQR()} />
                </div>
            </React.Fragment>
        )
    }
    const deleteCustomerDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteCustomerDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCustomer} />
        </>
    )
    return (
        <div className="grid crud-demo">
            <Dialog visible={displayQR} style={{ width: "50rem", textAlign: 'center' }} onHide={() => setDisplayQR(false)} footer={<Button label={t("download")} icon="pi pi-download" className="p-button-text" onClick={downloadAsPDF} />}>
                <div style={{ height: "auto", margin: "0 auto", width: "100%" }} ref={downloadContentRef}>
                    <div className="surface-0 text-700 text-center">
                        <img src={window.location.origin + "/assets/layout/images/LOGO.svg"} alt="Image" height="70" className="mr-0 lg:mr-6" />
                        <div className="text-900 font-bold text-5xl mb-3">gFood App</div>
                        <div className="text-700 text-2xl mb-1">Molimo skenirajte QR kod za pristup jelovniku</div>
                        <div className="text-700 text-2xl mb-1">Please scan QR code to see menu</div>

                    </div>
                    <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "50%", width: "50%" }}
                        value={customerPublicLink}
                        viewBox={`0 0 256 256`}
                    />
                </div>
            </Dialog>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-1" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    {/* {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>} */}
                    {!customerData ? <CateringBasicInfoSkeleton />
                        :
                        <div className="card">
                            <div className='grid'>
                                <div className='col-12 text-center'>
                                    {customerData?.name && <span> <h3>{customerData.name}</h3></span>}
                                </div>
                            </div>
                            <TabView className="tabview-header-icon">
                                <TabPanel header={t("basicInformation")} headerClassName="w-12rem pr-2 pl-2 align-content-center ">
                                    <div className="grid crud-demo">
                                        <div className="col-12">
                                            <div className="card">
                                                <Toolbar className="mb-4" left={leftInfoToolbarTemplate}></Toolbar>
                                                <CustomerBasicInfo customer={customerData} />
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header={t("locations")} headerClassName="w-12rem pr-2 pl-2  align-content-center">
                                    <CustomersLocations customerData={customerData} getCustomerData={getCustomerData} />
                                </TabPanel>
                                <TabPanel header={t("users")} headerClassName="w-12rem pr-2 pl-2  align-content-center">
                                    <CustomerUsers customerId={customerId} customerData={customerData} />
                                </TabPanel>
                                {(authContext?.roleDTO?.name == customerAdmin || authContext?.roleDTO?.name == cateringAdmin || authContext?.roleDTO?.name == cateringOperator || authContext?.roleDTO?.name == adminRole) &&
                                    <TabPanel header={t("settings")} headerClassName="w-12rem pr-2 pl-2  align-content-center">
                                        <CustomerConfiguration customerId={customerId} customer={customerData} />
                                    </TabPanel>}
                                {(authContext?.roleDTO?.name == customerAdmin || authContext?.roleDTO?.name == cateringAdmin || authContext?.roleDTO?.name == cateringOperator || authContext?.roleDTO?.name == adminRole) &&
                                    <TabPanel header={t("externalAuth")} headerClassName="w-12rem pr-2 pl-2  align-content-center">
                                        <ExternalAuthConfiguration customerId={customerId} customer={customerData} />
                                    </TabPanel>}
                            </TabView>
                        </div>
                    }
                    {updateCustomerDialog && <CustomerFormDialog customerDialog={updateCustomerDialog} updateCustomer={updateCustomer} hideDialog={hideDialog} customerToEdit={customerData} />}                    <Dialog visible={deleteCustomerDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deleteCustomerDialogFooter} onHide={hideDeleteCustomerDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {customerData && <span>{t("areYouSureYouWantToDelete")} <b>{customerData.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CustomerOverview, comparisonFn);
