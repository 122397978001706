import axios from 'axios';
import { BLCUrl, defaultHeader } from './serviceUtil';
const resourceName = "/menu"
const resourceNameShare = "/menu/share"
const resourceNameCreateAndShare = "/menu/createAndShare"

export default class MenuService {
    batchSave(requestBody) {
        return axios.post(BLCUrl + resourceName + "/batch", requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    save(requestBody) {
        return axios.post(BLCUrl + resourceName, requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    update(id, requestBody) {
        return axios.put(BLCUrl + resourceName + "/" + id, requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    search(searchData) {
        let searchParams = "?"
        if (searchData?.cateringId) {
            searchParams += "&cateringId=" + searchData.cateringId
        }
        if (searchData?.customerId) {
            searchParams += "&customerId=" + searchData.customerId
        }
        if (searchData?.template) {
            searchParams += "&template=" + searchData.template
        }
        if (searchData?.defaultMenu) {
            searchParams += "&defaultMenu=" + searchData.defaultMenu
        }
        if (searchData?.minDate) {
            searchParams += "&minDate=" + searchData.minDate
        }
        if (searchData?.maxDate) {
            searchParams += "&maxDate=" + searchData.maxDate
        }
        searchParams += "&pageNumber=" + searchData.pageNumber
        if (searchData?.pageSize) {
            searchParams += "&pageSize=" + searchData.pageSize
        }
        return axios.get(BLCUrl + resourceName + searchParams, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    searchPublic(searchData) {
        let searchParams = "?"
        if (searchData?.cateringId) {
            searchParams += "&cateringId=" + searchData.cateringId
        }
        if (searchData?.customerKey) {
            searchParams += "&customerKey=" + searchData.customerKey
        }
        if (searchData?.customerId) {
            searchParams += "&customerId=" + searchData.customerId
        }
        if (searchData?.template) {
            searchParams += "&template=" + searchData.template
        }
        if (searchData?.defaultMenu) {
            searchParams += "&defaultMenu=" + searchData.defaultMenu
        }
        if (searchData?.minDate) {
            searchParams += "&minDate=" + searchData.minDate
        }
        if (searchData?.maxDate) {
            searchParams += "&maxDate=" + searchData.maxDate
        }
        searchParams += "&pageNumber=" + searchData.pageNumber
        if (searchData?.pageSize) {
            searchParams += "&pageSize=" + searchData.pageSize
        }
        return axios.get(BLCUrl + resourceName + "/public" + searchParams, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    get(id) {
        return axios.get(BLCUrl + resourceName + "/" + id, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    delete(id) {
        return axios.delete(BLCUrl + resourceName + "/" + id, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    share(requestBody) {
        return axios.post(BLCUrl + resourceNameShare, requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    createAndShare(requestBody) {
        return axios.post(BLCUrl + resourceNameCreateAndShare, requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    searchActiveMenus(searchData) {
        let searchParams = "?"
        if (searchData?.cateringId) {
            searchParams += "&cateringId=" + searchData.cateringId
        }
        if (searchData?.customerId) {
            searchParams += "&customerId=" + searchData.customerId
        }
        if (searchData?.minDate) {
            searchParams += "&minDate=" + searchData.minDate
        }
        if (searchData?.maxDate) {
            searchParams += "&maxDate=" + searchData.maxDate
        }
        if (searchData?.pageSize) {
            searchParams += "&pageSize=" + searchData.pageSize
        }
        if (searchData?.pageNumber) {
            searchParams += "&pageNumber=" + searchData.pageNumber
        }
        return axios.get(BLCUrl + resourceName + "/active" + searchParams, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
}
