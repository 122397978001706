import moment from 'moment';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Ripple } from "primereact/ripple";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { withRouter } from "react-router-dom";
import MenuService from "../../service/MenuService";
import { searchMenuParams } from "../../service/serviceObjects";
import { addNewOrderToList } from "../../store/orderSlice";
import { isMobile } from "../../utilities/general";
import { groupMenuByCategory, truncateDescriptionString } from "../../utilities/menuItemUtil";
import PublicOrderComponentSkeleton from "./PublicOrderComponentSkeleton";
import DatePickerComponent from './DatePickerComponent';
import { StyleClass } from 'primereact/styleclass';

const PublicMenuComponent = (props) => {
    const { currentDay, currentCateringId, currentCustomerId, currentCustomerKey } = props
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [selectedItemCategory, setSelectedItemCategory] = useState(0);
    const [menuForCurrentDay, setMenuForCurrentDay] = useState([]);
    const [selectedDate, setSelectedDate] = useState(currentDay);
    const [categorizedMenuForCurrentDay, setCategorizedMenuForCurrentDay] = useState([]);
    const [previewItem, setPreviewItem] = useState();
    const [editId, setEditId] = useState();
    const menuService = new MenuService();
    const { t } = useTranslation();
    const messageToast = useRef(null);


    let userId = 0;
    const dispatch = useDispatch()
    const isEditEnabled = (currentElement) => {
        if (currentElement?.dateActiveFrom && currentElement?.dateActiveTo) {
            return moment().utc().isBetween(moment(currentElement.dateActiveFrom).utc(), moment(currentElement.dateActiveTo).utc())
        }
        return false
    }
    let editEnabled = isEditEnabled(menuForCurrentDay)

    const getActiveMenus = (first, last) => {
        let searchObject = {
            ...searchMenuParams,
            minDate: first,
            maxDate: last,
            customerId: currentCustomerId,
            cateringId: currentCateringId,
            customerKey: currentCustomerKey
        }
        setShowProgressBar(true)
        menuService.searchPublic(searchObject).then(data => {
            setTimeout(() => {
                setShowProgressBar(false)
            }, 500);
            setMenuForCurrentDay(data?.items?.[0])
            if (data?.items?.[0]?.menuItemDTOList) {
                setCategorizedMenuForCurrentDay(groupMenuByCategory(data?.items?.[0]?.menuItemDTOList))
            }
        })
    }
    useEffect(() => {
        setCategorizedMenuForCurrentDay([])
        setMenuForCurrentDay([])
        getActiveMenus(selectedDate, selectedDate)
        setEditId()
    }, [selectedDate])
    const onSelectItemCategory = (value) => {
        setSelectedItemCategory(value)
    }

    const returnOneMenuItem = (singleElement, mini, isEdit) => {
        return <div className="col-12 md:col-6 lg:col-3 mb-5 md:mb-0">
            <div className="p-2">
                <div className="relative mb-3">
                    <img src={singleElement.image ? "data:image/png;base64," + singleElement.image : window.location.origin + "/assets/layout/images/blank_menu_item.jpg"} className={mini ? "h-6rem w-full" : "h-10rem w-full"} style={{ resize: "horizontal", objectFit: 'cover' }} />
                    {(singleElement.isVegetarian || singleElement.isVegan || singleElement.isLenten) && <span className="bg-gray-50 text-gray-200 font-bold px-2 py-1 absolute" style={{ borderRadius: '1rem', left: '1rem', top: mini ? "0.1rem" : '1rem' }}>
                        <div className={`flex justify-content-left align-items-center ${mini ? "h-2rem min-h-2rem" : "h-3rem min-h-3rem"} `}>
                            <span className={`product-badge status`}>{singleElement.isVegetarian ? <img src={window.location.origin + "/assets/layout/images/vegetarian.jpg"} alt={singleElement.name} className={mini ? "h-1rem" : "h-2rem"} />
                                : <></>}</span>
                            <span className={`product-badge status`}>{singleElement.isVegan ? <img src={window.location.origin + "/assets/layout/images/vegan.png"} alt={singleElement.name} className={mini ? "h-1rem" : "h-2rem"} />
                                : <></>}</span>
                            <span className={`product-badge status`}>{singleElement.isLenten ? <img src={window.location.origin + "/assets/layout/images/posno.svg"} alt={singleElement.name} className={mini ? "h-1rem" : "h-2rem"} />
                                : <></>}</span>
                        </div>
                    </span>}
                    <span className="bg-gray-50 text-gray-200 font-bold px-2 py-1 absolute" style={{ borderRadius: '1rem', right: '1rem', bottom: mini ? "0.5rem" : '1rem' }}>
                        <span className="text-600">{singleElement?.price},00 RSD</span>

                    </span>

                    <span className="bg-gray-50 text-gray-200 font-bold px-3 py-3 absolute stretch-button" style={{ borderTopRightRadius: '1rem', left: '0', bottom: '0', cursor: 'pointer' }} onClick={() => setPreviewItem(singleElement)}>
                        <Ripple />
                        <i className="pi pi-info-circle text-blue-200" />

                    </span>
                </div>
                <div className="text-900 font-medium h-3rem" style={{ fontSize: singleElement?.name?.length > 30 ? '1em' : "1.3em" }}>{truncateDescriptionString(singleElement.name, 60)}</div>
                <div className="my-2 h-2rem" >
                    <span className="mr-3 h-full w-full" style={isMobile() ? { fontSize: '1em' } : { fontSize: "1em" }}>
                        {truncateDescriptionString(singleElement?.description)}
                    </span>
                    {/* <span className="text-500">12 reviews</span> */}
                </div>
            </div>
        </div>
    }
    const returnElementsForDate = (filterOutCategories) => {
        let edit = false
        if (filterOutCategories && categorizedMenuForCurrentDay) {
            edit = true
        }
        if (categorizedMenuForCurrentDay?.length > 0) {
            return <>

                <div className='grid col-12'>

                    {
                        categorizedMenuForCurrentDay.map(x => {
                            if ((!selectedItemCategory || selectedItemCategory == x.categoryName) && !filterOutCategories?.includes(x.categoryName)) {

                                return <div className='grid col-12 w-full'>

                                    <div className='col-12'>
                                        <div className="mt-1 font-bold text-left" style={isMobile() ? { fontSize: '7.2vw' } : { fontSize: '1.8vw' }}>{x.categoryName}</div>
                                    </div>
                                    <div className='col-12 w-full'>
                                        <div className="grid -mt-3 -ml-3 -mr-6">

                                            {x.elements.map(y => {

                                                return returnOneMenuItem(y, edit, edit)
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                        })
                    }
                </div>
            </>

        } else {
            return noMenuComponent
        }
    }
    let itemCategoryOptions = [
    ]
    if (categorizedMenuForCurrentDay?.length > 0) {
        itemCategoryOptions = categorizedMenuForCurrentDay.map(x => ({
            value: x.categoryName, name: x.categoryName
        }))
    }
    const itemCategoryButtonTemplate = (option) => {
        return <div className='w-full'>
            <div className='col-12 text-center'>
                <img src={option.image ? "data:image/png;base64," + option.image : window.location.origin + "/assets/layout/images/blank_category.png"} alt={option.name} className="w-3rem" />

            </div>
            <div className='col-12 text-center'>
                <span className="font-semibold">{option.name}</span>
            </div>
            {/* <i className="pi pi-tag mr-2" /> */}
        </div>
    }
    const onAddItem = (element) => {

        dispatch(addNewOrderToList({
            data: element,
            edit: false,
            currentDay: selectedDate,
            userId: userId,
            customerId: currentCustomerId,
            editId: editId
        }))
        showAddMessage(element)

    }
    const onEditItem = (element) => {
        dispatch(addNewOrderToList({
            data: element,
            edit: true,
            currentDay: selectedDate,
            userId: userId,
            customerId: currentCustomerId,
            editId: editId
        }))
        showAddMessage(element)
    }
    const showAddMessage = (element) => {
        messageToast.current.show({
            sevetiy: "custom-8",
            className: "surface-900",
            contentClassName: "p-4",
            life: 3000,
            content: (
                <div className="flex flex-column bg-gray-900 w-full">
                    <div className="flex flex-row bg-gray-900 w-full">
                        <div className="flex align-items-center">
                            <img src={element.image ? "data:image/png;base64," + element.image : window.location.origin + "/assets/layout/images/blank_menu_item.jpg"} className="w-7rem" style={{ resize: "horizontal" }} />

                        </div>
                        <p className="my-3 mx-3 line-height-3 text-gray-300">
                            {element.name} {t('isAdded')}
                        </p>
                    </div>
                    <div style={{ height: "6px" }} className="bg-gray-700">
                        <div className="h-full bg-blue-500 animate-width animation-duration-3000"></div>
                    </div>
                </div>
            ),
        });
    };
    const showErrorMessage = (element, maxNumber) => {
        messageToast.current.show({
            sevetiy: "warn",
            className: "surface-900",
            contentClassName: "p-4",
            life: 3000,
            content: (
                <div className="flex flex-column bg-orange-900 w-full">
                    <div className="flex flex-row bg-orange-900 w-full">
                        <i className="pi pi-exclamation-triangle text-4xl text-grey-300 my-2 mx-2" style={{ color: 'gray' }} />

                        <p className="my-2 mx-3 line-height-3 text-gray-300">
                            {element.itemCategoryName} {t('alreadyAdded')}
                        </p>
                    </div>
                    <div className="">
                        <p className="my-2 mx-3 line-height-3 text-gray-300">
                            {t('maxNumberOfOrderPerCategory')} : {maxNumber}
                        </p>
                    </div>
                    <div style={{ height: "6px" }} className="bg-gray-700">
                        <div className="h-full bg-blue-500 animate-width animation-duration-3000"></div>
                    </div>
                </div>
            ),
        });
    };
    const noMenuComponent = (
        <div className="col-12 w-full">
            <div className="shadow-2 flex flex-wrap justify-content-between align-content-center px-3 md:px-8" style={{ border: 1, borderRadius: '8px' }}>
                <div className="flex align-items-start py-6">
                    <div>
                        <span className="inline-flex border-circle justify-content-center align-items-center text-white bg-orange-400 mr-4" style={{ width: '38px', height: '38px' }}>
                            <i className="pi pi-times text-xl"></i>
                        </span>
                    </div>
                    <div>
                        <div className="text-500 text-2xl font-bold mt-2">{t('noMenu')}</div>
                    </div>
                </div>

            </div>
        </div>

    )
    return (
        <div className='w-full'>
            <div style={{ height: '3rem', backgroundImage: 'linear-gradient(to right, #ff7e5f, #feb47b)', }}>
                <div className="py-3 px-5 flex align-items-center justify-content-between relative lg:static" >
                    <img src={window.location.origin + "/assets/layout/images/logo-white.png"} alt="Image" height="20rem" className="" />


                </div>

            </div>
            <div className="surface-0 text-700 text-center mt-2">
            <img src={"data:image/png;base64," + menuForCurrentDay?.cateringLogo} alt="Image" height="70" className="mr-0 lg:mr-6" />
            <div className="text-900 font-bold text-5xl mb-3">{menuForCurrentDay?.cateringName}</div>
            </div>
        
            <div className='col-12 p-2'>
                <DatePickerComponent
                    value={selectedDate}
                    onChange={(value) => setSelectedDate(value)}
                />
            </div>
            <Toast key="block8" style={{ width: "30rem" }} ref={messageToast} />
            {previewItem && <Dialog visible={previewItem} appendTo="self" modal breakpoints={{ '960px': '75vw', '640px': '90vw' }} style={{ width: '700px' }} showHeader={false} contentStyle={{ padding: '2rem 2rem 1rem 2rem' }}>
                <div className="grid relative">
                    <div className="col-12 lg:col-6 text-center">
                        <img src={previewItem.image ? "data:image/png;base64," + previewItem.image : "assets/layout/images/blank_menu_item.jpg"} className={"h-20rem max-h-20rem w-full"} style={{ resize: "vertical", objectFit: 'cover' }} />
                    </div>
                    <div className="col-12 lg:col-6 py-0 lg:pl-5">
                        <div className="flex justify-content-end">
                            <Button className="absolute lg:static p-button-text p-button-plain p-button-rounded" icon="pi pi-times pi-2xl" onClick={() => setPreviewItem()} style={{ right: '0rem', top: '0rem' }} />
                        </div>
                        <div className="flex align-items-center justify-content-between mb-3">
                            <span className="text-xl font-medium text-900">{previewItem.name}</span>
                        </div>
                        <div className="flex align-items-center justify-content-between mb-3">
                            <div className="text-xl text-900">{previewItem.price},00 RSD</div>
                            <div className='flex justify-content-left align-items-center h-3rem min-h-3rem'>
                                <span className={`product-badge status`}>{previewItem.isVegetarian ? <img src={window.location.origin + "/assets/layout/images/vegetarian.jpg"} alt={previewItem.name} className="h-2rem" />
                                    : <></>}</span>
                                <span className={`product-badge status`}>{previewItem.isVegan ? <img src={window.location.origin + "/assets/layout/images/vegan.png"} alt={previewItem.name} className="h-2rem" />
                                    : <></>}</span>
                                <span className={`product-badge status`}>{previewItem.isLenten ? <img src={window.location.origin + "/assets/layout/images/posno.svg"} alt={previewItem.name} className="h-2rem" />
                                    : <></>}</span>
                            </div>
                        </div>
                        <p className="p-0 mt-0 mb-3 line-height-3 text-700">
                            {previewItem.description}
                        </p>
                        {previewItem?.menuItemAttributeDTOList?.length > 0 &&
                            <ul className="list-none p-0 m-0">
                                {previewItem?.menuItemAttributeDTOList.map(x => {
                                    if (x.isPublic) {
                                        return <li className="flex align-items-center mb-2">
                                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                            <span className="text-900 text-sm">{x.name}</span>
                                        </li>
                                    }
                                })}
                            </ul>


                        }


                    </div>
                </div>
            </Dialog >}
            {
                showProgressBar ? <PublicOrderComponentSkeleton /> :
                    <div className="col-12 grid">
                        <div className="col-12 md:col-10 lg:col-10 xm:col-12 text-center ml-0 md:ml-6 lg:ml-6 xl:ml-6">

                        </div>

                        <div className='col-12 ml-5'>
                            <SelectButton className='category-select-button' optionLabel="name" value={selectedItemCategory} itemTemplate={itemCategoryButtonTemplate} options={itemCategoryOptions} onChange={(e) => onSelectItemCategory(e.value)}></SelectButton>

                        </div>
                        <div className='col-12'>
                            <div className="surface-section px-4 py-3 md:px-6 lg:px-8 sm:align-items-center">

                                <div className="grid -mt-3 -ml-3 -mr-3">
                                    {returnElementsForDate()}
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <div className="surface-section px-4 py-6 md:px-6 lg:px-8 text-center">
                <img src={window.location.origin + "/assets/layout/images/LOGO.svg"} alt="Image" height="50" />
                <div className="font-medium text-900 mt-4 mb-3">&copy; 2024 bidtech.rs</div>
                <p className="text-600 line-height-3 mt-0 mb-4">Explore food world with us.</p>
                <div className="flex align-items-center justify-content-center">
                    <a className="cursor-pointer text-700 mr-5" onClick={() => window.location = "/"}>
                        <i className="pi pi-home"></i>
                    </a>
                    <a className="cursor-pointer text-700" href='https://www.instagram.com/gfood.app'>
                        <i className="pi pi-instagram"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default withRouter(PublicMenuComponent);